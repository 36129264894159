import React from "react";
import ReactDOM from "react-dom/client";
import DefaultWaiting from "./pages/waiting/DefaultWaiting";
import "./index.css";
import "./SearchAddressBox.css";

const ApplicationConfigs = React.lazy(() => import("./ApplicationConfigs"));

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
    <React.StrictMode>
        <React.Suspense fallback={<DefaultWaiting/>}>
            <ApplicationConfigs/>
        </React.Suspense>
    </React.StrictMode>
);
