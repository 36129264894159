import React from "react";

const container = {
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(230,230,230,0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};
const circle = {backgroundColor: "rgba(0,0,0,0.8)"};

export default function DefaultWaiting() {
    return (
        <div style={container}>
            <div className="lds-ellipsis">
                <div style={circle}></div>
                <div style={circle}></div>
                <div style={circle}></div>
                <div style={circle}></div>
            </div>
        </div>
    );
}
